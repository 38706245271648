/* Dashboard.module.css */
.dashboard {
  padding: 20px;
  margin-left: 6rem !important;
}

.inquiredList {
  padding: 0;
  list-style: none;
}

.inquiredList .list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.receivedOffers {
  margin-top: 20px;
}

.progress-circle {
  position: relative;
  width: 100px;
  height: 100px;
}

.circle {
  width: 100%;
  height: 100%;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.padding {
  padding: 1rem;
}

.w-69 {
  width: 69%;
}

.w-29 {
  width: 29%;
}

.w-49 {
  width: 49%;
}

.w-100 {
  width: 100%;
}

.barTitle p,
.stack-2-title p {
  font-size: 11px;
  color: #898787;
}

.stack-2-title {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 600;
}

.stack-2-title h4 {
  font-size: 16px;
  font-weight: 600;
}

.barTitle h4 {
  font-weight: 600;
}

.tableWrapper {
  height: auto;
  padding: 1rem;
  font-size: 13px;
  background-color: white;
  background: white;
  width: -webkit-fill-available;
}

/* .tableWrapper .last {
  color: #898787;
} */

.tableWrapper table {
  margin: 0;
}

.title-cheapest-alc {
  font-weight: 600;
  padding-bottom: 8px;
  border-bottom: 1px solid #8080803d;
}

/* .tableWrapper th, .tableWrapper td {
  border-right: 0 !important;
} */

.column-1 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.column-2 {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.doughnut {
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*** FILTER SECTION ***/
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.sizeMessage {
  font-size: 11px;
  color: #ff4d4d;
  position: absolute;
}

.alcohol-info {
  display: flex;
  gap: 10px;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.selected-filter {
  background: #f3f2ff;
  border-radius: 6px;
  /* font-weight: 600; */
  padding: 4px 12px;
  border: 1px solid #d8d5ff;
}
