/* src/AdminPanel.module.css */
.adminPanel {
  margin: 20px 0;
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
}

.table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 13px;
}

.col-value {
  display: flex;
  align-items: center;
}

.input-label {
  font-size: 12px;
}

.qtyInput {
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 3px 10px;
  font-size: 10px !important;
  /* align-items: center; */
  color: #9a9a9a;
  width: auto;
}

.qtyInput:focus {
  outline: none;
  border: 1px solid #ccc;
}

/*** SAVE DOLLAR RATE BUTTON ***/
.statusContainer {
  display: flex;
  gap: 10px;
}

.price-button {
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 2px 12px;
  bottom: 0;
  margin-left: 6px;
  background-color: #e2e0ff;
  color: #4339f2;
  border: 1px solid;
  box-shadow: 0px 0px 4px 0px;
}
.price-button:hover {
  transform: scale(1.05);
}

.cancel-button {
  border-radius: 4px;
  font-size: 11px;
  display: flex;
  align-items: center;
  padding: 2px 12px;
  bottom: 0;
  margin-left: 6px;
  background-color: #ffe0e6;
  color: #f2395e;
}

/*** FILTER SECTION ***/
.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

