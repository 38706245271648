body {
  margin: 0;
  /* font-family: "Poppins", sans-serif !important; */
  font-family: "Times New Roman", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f9fa !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-1: #4339f2;
  --color-2: #ff3333;
  --color-3: #34b53a;
  --color-4: #c6ebc8;
  --color-5: #ebf8eb;
  --color-6: #ffb200;
  --color-7: #fff7e6;
}

.m-color-blue {
  color: var(--color-1) !important;
}
.m-color-red {
  color: var(--color-2) !important;
}
.m-color-green {
  color: var(--color-3) !important;
}
.m-color-light-green {
  color: var(--color-4) !important;
}
.m-color-lightest-green {
  color: var(--color-5) !important;
}
.m-color-yellow {
  color: var(--color-6) !important;
}
.m-color-light-yellow {
  color: var(--color-7) !important;
}

.m-color-blue-bg {
  background: var(--color-1) !important;
}
.m-color-red-bg {
  background: var(--color-2) !important;
}
.m-color-green-bg {
  background: var(--color-3) !important;
}
.m-color-light-green-bg {
  background: var(--color-4) !important;
}
.m-color-lightest-green-bg {
  background: var(--color-5) !important;
}
.m-color-yellow-bg {
  background: var(--color-6) !important;
}
.m-color-light-yellow-bg {
  background: var(--color-7) !important;
}

.fw-700 {
  font-weight: 700 !important;
}

thead th {
  /* color: #898787 !important; */
  color: black;
  font-weight: 700;
  min-width: 80px;
  vertical-align: middle;
  position: sticky;
  top: 0;
}

tbody td {
  vertical-align: middle;
}

/*** LOADER ***/
.loader {
  position: fixed;
  background: white;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 999999;
}

.data-loader {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}

.loading-container {
  height: 7rem;
  position: relative;
}

.ml-5rem {
  margin-left: 5rem !important;
}

.pe-cursor {
  cursor: pointer;
}

.fs-13 {
  font-size: 13px;
}

.activePage > .page-link {
  background-color: #4339f2;
  color: white;
}

/*** POSITIONING ***/
.sticky-top {
  position: sticky;
  top: 0;
}

.sticky-left {
  position: sticky;
  left: 0;
  border: 1px solid #8888883b;
  border-left: 0;
}

.sticky-right {
  position: sticky;
  right: 0;
}

.page-container {
  margin-left: 5rem !important;
  margin-top: 2rem !important;
}
