.supplierCard {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  font-size: 13px;
  border: none !important;
}

.status-supplier {
  display: flex;
  font-size: 9px;
  height: 20px;
  align-items: center;
  padding: 5px 10px 5px 0;
  border-radius: 10px;
}

.onboarded {
  background: #ebf8eb;
  color: #34b53a;
}

.offboarded {
  background-color: #ffe2e2;
  color: #af4c4c;
}

.tableContainer {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  font-size: 13px;
  border: none !important;
}

.supplier-container {
  background-color: #f8f9fa;
  padding: 20px 0 20px 20px;
  border-radius: 8px;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: #f8f9fa;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-size: 23px !important;
  font-weight: bold;
  margin: 0;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.table tr td:first-child,
.table tr th:first-child {
  border-left: 0;
}

.table tr td:last-child,
.table tr th:last-child {
  border-right: 0;
}

.tableContainer tr td:first-child,
.tableContainer tr th:first-child {
  padding-left: 20px;
  padding-right: 20px;
}

.table td {
  padding: 1rem 12px !important;
  vertical-align: middle;
}

.cardOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 12px;
  margin-bottom: 0 !important;
}

.cardTwo table {
  border-top: 1px solid #dee2e6;
}

.cardTwoTitle {
  font-size: 13px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 1.14rem 12px;
  margin-bottom: 0 !important;
}

.cardOne h4 {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
}
.tableColumns {
  color: #898787;
  font-weight: 400;
}

/*** STYLES FOR OFFER MANGEMENT ***/
.tableOffer {
  font-size: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  border-radius: 10px;
  width: 1094px;
}

.table-wrapper {
  display: flex;
  gap: 24px;
  flex-wrap: wrap-reverse;
}

.table-wrapper td {
  height: 4.5rem;
  vertical-align: middle;
}
.table-wrapper th {
  vertical-align: middle;
}

.col-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.statusContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.status {
  padding: 3px 10px;
  border-radius: 32px;
  font-size: 11px;
  display: flex;
}

.statusNoQuery {
  background-color: #ffffde;
  color: #bfaa32;
}

.statusPending {
  background-color: #fff7de;
  color: #ff9800;
}

.statusRejected {
  background-color: #ffe2e2;
  color: #af4c4c;
}

.actions-tags-purchased {
  background: #e7e6ff;
  color: #ad00ff;
}

.statusRequest {
  background: #ecebfe;
  color: #4339f2;
}

/*** VIEW OFFERS BUTTON ***/

.view-offers {
  background-color: #e2e0ff;
  color: #4339f2;
  text-align: center;
}

/** EMAIL VIEWER **/
.email-viewer {
  position: relative;
  border-radius: 5px;
  box-shadow: 0 4px 8px #0000001a;
  font-size: 13px;
  background-color: white;
  border-radius: 8px;
  padding: 0 12px;
  width: -webkit-fill-available;
  transition: all 0.3s ease-in-out;
}

.email-viewer > button {
  position: absolute;
  top: 10px;
  right: 50px;
}

/* Media queries for responsiveness */

/* @media only screen and (max-width: 1199px) {
  .tableOffer {
    width: 913px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .tableOffer {
    width: 672px;
  }
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  .tableOffer {
    width: 492px;
  }
} */

@media (min-width: 576px) {
  .tableOffer {
   width: 496px;
  }
}

@media (min-width: 768px) {
  .tableOffer {
   width: 676px;
  }
}

@media (min-width: 992px) {
  .tableOffer {
   width: 936px;
  }
}

@media (min-width: 1200px) {
  .tableOffer {
   width: 1116px;
  }
}

@media (min-width: 1400px) {
  .tableOffer {
   width: 1296px;
  }
}

@media (min-width: 1600px) {
   .tableOffer {
   width: 1472px;
  }
}

@media (min-width: 1920px) {
   .tableOffer {
   width: 1776px;
  }
}

/*** RESPONSIVE SCREEN SIZES ***/
/** 1200px, 992px, 768px, 576px ***/
